<template>
  <div class="notification-bar" :class="notificationTypeClass">
    <v-chip label text-color="white" color="#0066A1">
      <h3>{{ notification.message }}</h3>
    </v-chip>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    notification: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      timeout: null
    };
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  computed: {
    notificationTypeClass() {
      return `-text-${this.notification.type}`;
    }
  },
  methods: mapActions('notification', ['remove'])
};
</script>

<style scoped>
.notification-bar {
  margin: 1em 0 0;
}
</style>
