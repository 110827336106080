<template>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-row>
        <v-col md="2">
          {{ formatDateFull(log.date) }}
        </v-col>
        <v-col md="1">
          {{ formatDate(log.updatedAt) }}
        </v-col>
        <v-col md="1">
          {{ log.sync }}
        </v-col>
        <v-col md="1">
          {{ log.type }}
        </v-col>
        <v-col md="7">
          {{ log.message }}
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row>
        <v-col md="1" class="caption grey--text">
          Класс
        </v-col>
        <v-col md="1" class="caption grey--text">
          Метод
        </v-col>
        <v-col md="5" class="caption grey--text">
          Описание
        </v-col>
        <v-col md="5" class="caption grey--text">
          Данные
        </v-col>
      </v-row>
      <v-row class="fixLogListItem">
        <v-col md="1">
          {{ log.name }}
        </v-col>
        <v-col md="1">
          {{ log.method }}
        </v-col>
        <v-col md="5">
          {{ log.description }}
        </v-col>
        <v-col md="5">
          {{ log.data }}
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import ViewHelper from '@/helpers/view.helper';

/**
 * type: log
 * value: id          - Ид в базе
 * value: type        - Тип лога [warning, error, exception]
 * value: name        - Имя класса
 * value: method      - Имя метода
 * value: message     - Краткое сообщение проблемы
 * value: description - Подробное описание проблемы
 * value: data        - Данные при запросе
 * value: date        - Дата события
 * value: sync        - Количество запросов для конкретного события
 * value: createdAt   - Дата создания записи
 * value: updatedAt   - Дата обновления записи
 */
export default {
  props: {
    log: Object
  },
  methods: {
    formatDateFull: ViewHelper.formatDateFull,
    formatDate: ViewHelper.formatDate
  }
};
</script>
<style scoped>
.fixLogListItem {
  overflow-wrap: anywhere;
  word-wrap: break-word;
  word-break: normal;
  hyphens: auto;
}
</style>
