<template>
  <div class="Cashiers">
    <h1 class="subtitle-1 grey--text">Лог ошибок. Выборка по дате события или последнего обновления статуса.</h1>
    <div>
      <v-row class="mx-0 mt-2 grey lighten-3 mb-0">
        <v-col md="4" class="d-flex">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text @click="downloadReport" v-bind="attrs" v-on="on">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
            </template>
            <span>Выгрузить в Excel</span>
          </v-tooltip>
          <v-menu
            ref="dateRangePicker"
            v-model="isDateRangePickerVisible"
            :close-on-content-click="false"
            :return-value.sync="dateRange"
            transition="slide-y-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                class="ml-3"
                prepend-inner-icon="mdi-calendar-range"
                hide-details
                readonly
                flat
                solo
                dense
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="datePickerRange" :first-day-of-week="1" :max="`${currentDate}`" @change="onDatePickerRangeChange" no-title range scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="isDateRangePickerVisible = false">Отмена</v-btn>
              <v-btn text color="primary" @click="onDatePickerRangeSave">OK</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="8" class="pt-0 pt-md-3">
          <v-text-field v-model="search" placeholder="Тип, сообщение" prepend-inner-icon="mdi-magnify" hide-details single-line clearable dense flat solo />
        </v-col>
      </v-row>
      <div v-if="this.isLogsLoading">
        Загружаем список логов
      </div>
      <div v-else-if="this.isLogsError">
        Ошибка загрузки списка логов
      </div>
      <div v-else-if="!this.logs || this.logs.length <= 0">
        По выбранным параметрам логи отсутствуют
      </div>
      <div v-else>
        <div>Логи: {{ this.logs.length }}</div>
        <LogList :logs="filteredReceipts" />
      </div>
    </div>
    <NotificationContainer />
  </div>
</template>

<script>
import LogList from '@/components/log/LogList';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import LogService from '@/services/LogService';
import NotificationContainer from '@/components/notification/NotificationContainer.vue';
export default {
  data() {
    const today = moment().format('YYYY-MM-DD');
    return {
      isDateRangePickerVisible: false,
      datePickerRange: [today, today],
      dateRange: [today, today],
      receiptTypeSelect: [0, 1],
      delayedPayStatusSelect: [0, 1, 2, 3],
      currentDate: today,
      search: null,
      timer: null
    };
  },
  components: {
    NotificationContainer,
    LogList
  },
  computed: {
    queryParams() {
      const params = new URLSearchParams();
      params.append('start', this.datePickerRange[0]);
      params.append('end', this.datePickerRange[1]);
      return params;
    },
    filteredReceipts() {
      return this.logs.filter(s => this.search == null || s.type.includes(this.search) || s.message.includes(this.search));
    },
    dateRangeText() {
      const dateFrom = moment(this.datePickerRange[0]);
      const dateTo = moment(this.datePickerRange[1]);
      return this.formatDateRangeText(dateFrom, dateTo);
    },
    ...mapGetters('log', ['logs']),
    ...mapGetters('log', ['isLogsLoading']),
    ...mapGetters('log', ['isLogsError'])
  },
  mounted() {
    this.fetchLogsWithFilter();
  },
  methods: {
    onDatePickerRangeChange() {
      if (this.datePickerRange[1] < this.datePickerRange[0]) {
        this.datePickerRange.reverse();
      }
    },
    onDatePickerRangeSave() {
      if (this.datePickerRange.length === 1) {
        this.datePickerRange.push(this.currentDate);
      }
      this.$refs.dateRangePicker.save(this.datePickerRange);
      this.fetchLogsWithFilter();
    },
    formatDateRangeText(dateFrom, dateTo) {
      if (dateTo.diff(dateFrom) === 0) {
        return `${dateFrom.format('D MMM')}`;
      }
      let dateFromFormat, dateToFormat;
      dateFromFormat = dateToFormat = 'D MMM YYYY';
      if (dateFrom.year() === dateTo.year()) {
        if (dateFrom.year() === this.$moment(this.currentDate).year()) {
          dateToFormat = 'D MMM';
        }
        if (dateFrom.month() === dateTo.month()) {
          dateFromFormat = 'D';
        } else {
          dateFromFormat = 'D MMM';
        }
      }

      return `С ${dateFrom.format(dateFromFormat)} по ${dateTo.format(dateToFormat)}`;
    },
    downloadReport() {
      LogService.downloadLogsExcel(this.queryParams).then(res => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([res.data]));
        link.setAttribute('download', `Логи с ${this.datePickerRange[0]} по ${this.datePickerRange[1]} на ${moment().format('YYYY-MM-DD HH-mm-ss')}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    },
    fetchLogsWithFilter() {
      this.fetchLogsForDates({
        queryParams: this.queryParams
      });
    },
    ...mapActions('log', ['fetchLogsForDates'])
  }
};
</script>

<style></style>
